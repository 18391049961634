<template>
	<div
		:class="'h-' + size + ' w-' + size + ' max-h-' + size + ' max-w-' + size + ' min-h-' + size + ' min-w-' + size + ' flex sm:block rounded-full border border-primary bg-white'"
		:title="fullName ? fullName : firstName && lastName ? firstName + ' ' + lastName : userStore.getUserFullName"
	>
		<div class="flex h-full w-full justify-center items-center select-none">
			<span :class="'font-semibold text-black select-none ' + classes">{{ firstName && lastName ? getInitials(firstName, lastName) : userStore.getUserInitials }}</span>
		</div>
	</div>
</template>

<script setup lang="ts">
	import { useUserStore } from '@/modules/user/store';
	import { defineProps, withDefaults } from 'vue';
	import { getInitials } from '@/utils/helpers.js';

	const userStore = useUserStore();

	interface Props {
		firstName?: string;
		lastName?: string;
		fullName?: string;
		size?: number;
		classes?: string;
	}

	withDefaults(defineProps<Props>(), {
		firstName: undefined,
		lastName: undefined,
		fullName: undefined,
		size: 12,
		classes: 'text-xl',
	});
</script>
